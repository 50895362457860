import React from 'react';
import Header2 from './header2';
import Footer from './footer';

function About() {
  return (
   <>
  <Header2 />
  <div className='container about-container'>
    <p className='about-text'>Unlock the power of print with Inndus, where creativity meets precision. As a leading printing company and a global Xerox Premier Partner, we're dedicated to bringing your visions to life with exceptional quality and attention to detail.
From business cards that make a lasting impression to vibrant posters that captivate audiences, we offer a comprehensive range of printing services tailored to meet your needs. Our state-of-the-art equipment and skilled team ensure that every project is executed with precision and professionalism.
At Inndus, we understand the importance of deadlines. That's why we're committed to delivering your projects on time, every time, without compromising on quality.
Whether you're a small business looking to make a big impact or a large corporation seeking top-notch printing solutions, we've got you covered. Let us be your partner in print, turning your ideas into reality.
Explore our services today and discover why businesses trust Inndus for all their printing needs. Let’s create something extraordinary together.</p>
 <p className='about-us-p'> WE ARE PASSIONATE ABOUT PRINITNG!</p>
  </div>
  <Footer />
   </>
  )
}

export default About