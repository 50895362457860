import React, { useState } from 'react';
import Header2 from './header2';
import Footer from './footer';
import axios from 'axios';

function Contact() {
    const baseURL = process.env.REACT_APP_API_BASE_URL;

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contact: '',
    subject: '',
    message: ''
  });

  const [errors, setErrors] = useState({});
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, contact, subject, message } = formData;
    const newErrors = {};

    if (!name) newErrors.name = "Name is required";
    if (!email) newErrors.email = "Email is required";
    if (!contact) newErrors.contact = "Contact is required";
    if (!subject) newErrors.subject = "Subject is required";
    if (!message) newErrors.message = "Message is required";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      // Vibrate the fields with errors
      const errorFields = Object.keys(newErrors);
      errorFields.forEach(field => {
        document.querySelector(`[name="${field}"]`).classList.add('vibrate');
        setTimeout(() => {
          document.querySelector(`[name="${field}"]`).classList.remove('vibrate');
        }, 500);
      });
      return;
    }

    try {
      const response = await axios.post(`${baseURL}/api/contact`, formData);
      if (response.status === 200) {
       
        setFormData({
          name: '',
          email: '',
          contact: '',
          subject: '',
          message: ''
        });
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  return (
    <>
      <Header2 />
      <div className='container contact-us-container'>
        {/* <p className='contact-us-title'>Contact Us</p> */}
        <div className='row contact-us-row'>
          <div className='col-sm-6'>
            <div className='card'>
              <div className='card-header'>
                <p className='card-header-title'>Contact Us</p>
              </div>
              <div className='card-body'>
                <div className='second-div-card-about'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-house" viewBox="0 0 16 16"><path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5z"/></svg>
                  <p className='contact-contact-us'><span className='title-span'>INNDUS</span><br/>
                    Catalyst House<br/>
                    720 Centennial Court, Centennial Park<br/>
                    Elstree, Hertfordshire WD6 3SY</p>
                </div>
                <div className='second-div-card-about'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-telephone" viewBox="0 0 16 16"><path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/></svg>
                  <p className='contact-contact-us'>020 3375 3364</p>
                </div>
                <div className='third-div-card-about'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16"><path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/></svg>
                  <p className='contact-contact-us'>info@inndus.com</p>
                </div>
              </div>
            </div>
          </div>

          {/* <div className='col-sm-6'>
            <div className='card'>
              <div className='card-header'>
                <p className='card-header-title'>Get in touch</p>
              </div>
              <div className='card-body'>
                <form onSubmit={handleSubmit}>
                  <div className='form-group row card-group-about'>
                    <label className='card-label-about'>*Name</label>
                    <input
                      type='text'
                      name='name'
                      className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                    {errors.name && <div className='invalid-feedback'>{errors.name}</div>}
                  </div>
                  <div className='form-group row card-group-about'>
                    <label className='card-label-about'>*Email</label>
                    <input
                      type='text'
                      name='email'
                      className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                    {errors.email && <div className='invalid-feedback'>{errors.email}</div>}
                  </div>
                  <div className='form-group row card-group-about'>
                    <label className='card-label-about'>*Contact</label>
                    <input
                      type='text'
                      name='contact'
                      className={`form-control ${errors.contact ? 'is-invalid' : ''}`}
                      value={formData.contact}
                      onChange={handleInputChange}
                    />
                    {errors.contact && <div className='invalid-feedback'>{errors.contact}</div>}
                  </div>
                  <div className='form-group row card-group-about'>
                    <label className='card-label-about'>*Subject</label>
                    <input
                      type='text'
                      name='subject'
                      className={`form-control ${errors.subject ? 'is-invalid' : ''}`}
                      value={formData.subject}
                      onChange={handleInputChange}
                    />
                    {errors.subject && <div className='invalid-feedback'>{errors.subject}</div>}
                  </div>
                  <div className='form-group row card-group-about'>
                    <label className='card-label-about'>*Message</label>
                    <textarea
                      name='message'
                      className={`form-control ${errors.message ? 'is-invalid' : ''}`}
                      value={formData.message}
                      onChange={handleInputChange}
                    />
                    {errors.message && <div className='invalid-feedback'>{errors.message}</div>}
                  </div>
                  <div className='col'>
                    <button type='submit' className='btn contact-us-btn'>Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Contact;
